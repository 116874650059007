export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeFileExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex !== -1) {
    return fileName.substring(0, lastDotIndex);
  }
  return fileName;
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  return currentDate.toISOString().slice(0, 10); // Get YYYY-MM-DD format
};

export const resizeImage = async (file, maxSize) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const bitmap = await createImageBitmap(file);
  const { width, height } = bitmap;
  var newWidth = width;
  var newHeight = height;
  if (width > height) {
    if (width > maxSize) {
      newHeight *= maxSize / width;
      newWidth = maxSize;
    }
  } else {
    if (height > maxSize) {
      newWidth *= maxSize / height;
      newHeight = maxSize;
    }
  }

  canvas.width = newWidth;
  canvas.height = newHeight;
  ctx.drawImage(bitmap, 0, 0, newWidth, newHeight);

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        const modifiedFile = new File([blob], removeFileExtension(file.name) + getCurrentDate() + ".jpeg", { type: "image/jpeg" });
        resolve(modifiedFile);
      },
      "image/jpeg",
      0.8
    );
  });
};

// we use this to check if value is content coming from the DraftJS editor or just text. 
// Problem is that a text, starting and ending with " is also valid JSON
// i.e. "This is a new event" => Valid Json
export const checkIfStringIsJson = (value) => {
  try {
    const converted = JSON.parse(value);
    return Object.hasOwn(converted, "blocks");
  } catch (err) {
    return false;
  }
};

export const GetDEEPLDUMMYTRANS = () => {
  return {
    translations: [
      {
        detected_source_language: "EN",
        text: "Euronav is marktleider in het transport van ruwe olie. Als 's werelds grootste, onafhankelijke beursgenoteerde platform voor ruwe tankers, op 31 maart 2022, bezit en beheert Euronav een vloot van 72 schepen (zie Euronav vloot p 41). De vennootschap, opgericht in België, heeft haar hoofdkantoor in Antwerpen. Wereldwijd heeft Euronav ongeveer 200 vaste werknemers aan de wal en kantoren over heel Europa en Azië.\nOngeveer 3.200 mensen werken op de schepen. Euronav is uitgegroeid van een familiebedrijf met 17 schepen tot een sterke internationale speler die genoteerd is op Euronext Brussel en op de NYSE onder het symbool 'EURN'. De noodzaak om een veilige en betrouwbare vloot uit te baten is nog nooit zo cruciaal geweest en is de belangrijkste strategische doelstelling voor de Vennootschap. Euronav wil een efficiënte organisatie zijn en streeft ernaar de hoogste kwaliteit en de best mogelijke service te leveren aan haar klanten.",
      },
    ],
  };
};
