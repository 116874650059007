import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { EditorState, convertFromRaw, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const MDEditorContainer = styled.div`
  .rdw-link-modal-btn {
    font-size: 14px;
    color: #2a2a2a;
  }
  .rdw-link-modal-input {
    color: #2a2a2a;
    font-size: 14px;
  }
  .md-editor {
    background-color: white;
    height: 275px !important;
    border: 1px solid #f1f1f1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    .public-DraftEditor-content {
      color: black;
      a {
        color: var(--color-accent-prim);
        cursor: pointer;
      }
      img {
        width: 24px;
        height: 24px;
        display: none;
      }
    }
    &-toolbar {
      padding: 6px 5px 0;
      border-radius: 2px;
      border: 1px solid #f1f1f1;
      display: flex;
      justify-content: flex-start;
      background: white;
      flex-wrap: wrap;
      font-size: 14px;
      margin-bottom: 5px;
      user-select: none;
      color: black;
      .rdw-option-wrapper {
        min-width: unset;
      }
      span {
        font-size: 1.4rem;
        color: black;
      }
      input {
        color: var(--color-black);
      }
      button { 
        font-size: 16px;
      }
    }

    .rdw-embedded-modal {
      left: initial;
      right: 10px;
    }

  }
`;

// value (state) is living in MDEditor (=object), setFIeldvalue is to set the value withing Formik (=string)

const MDEditor = ({ value, setFieldValue, languageCode, onTranslate }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const updateTextDescription = async (state) => {
    await setEditorState(state);

    const raw = convertToRaw(state.getCurrentContent());
    const stringRaw = JSON.stringify(raw);
    if (setFieldValue) {
      console.log(stringRaw);
      setFieldValue(stringRaw);
    }
  };

  useEffect(() => {
    if (!value) return;
    console.log(typeof value);
    try {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(value))));
    } catch (err) {
      const contentBlock = htmlToDraft(value);
      console.log(contentBlock?.contentBlocks);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, []);

  useEffect(() => {
    if (!value) return;
    console.log(typeof value);
    try {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(value))));
    } catch (err) {
      const contentBlock = htmlToDraft(value);
      console.log(contentBlock?.contentBlocks);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [onTranslate]);

  /*useEffect(() => {
    if (!languageCode) return;
    console.log("languageCode", languageCode);
    console.log("paste text");
    const contentBlock = convertFromHTML(proposedTranslation);
    console.log(contentBlock?.contentBlocks);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [languageCode]);*/

  /* https://draftjs.org/docs/api-reference-editor/#handlepastedtext */

  const handleEmbedLinkInput = (url) => {
    const youtubeRegex = /https?:\/\/(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^\s&]+)/i;

    let embeddedUrl = url;
    const match = youtubeRegex.exec(url);
    if (match) {
      const videoId = match[3];
      embeddedUrl = `https://www.youtube.com/embed/${videoId}`;
    }

    // Call the embedCallback with the embedded URL
    return embeddedUrl;
  };

  return (
    <MDEditorContainer>
      <Editor
        editorStyle={{ height: "40rem", fontSize: 14, fontFamily: "Arial" }}
        editorState={editorState}
        toolbarClassName="md-editor-toolbar"
        wrapperClassName="md-editor-wrapper__inner"
        editorClassName="md-editor"
        handlePastedText={() => false}
        onEditorStateChange={updateTextDescription}
        toolbar={{
          options: ["inline", "list", "textAlign", "fontSize", "fontFamily", "colorPicker", "history", "link", "embedded"],
          fontSize: {
            options: [12, 14, 16, 18, 20],
          },
          fontFamily: {
            options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic"],
          },
          embedded: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            embedCallback: (url) => {
              const embeddedUrl = handleEmbedLinkInput(url);
              return embeddedUrl; // Return the converted embedded URL to be handled by the toolbar
            },
          },
        }}
      />
    </MDEditorContainer>
  );
};
export default MDEditor;
